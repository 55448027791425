import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { File as ModelFile } from "../data/models/file";
import Translator from "./Translator";

export interface DataTypes {
  name: string | null;
}

interface UpdateRenameFileProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (data: DataTypes) => void;
  file: ModelFile;
}
const UpdateRenameFile = ({
  isOpen,
  onClose,
  onUpdate,
  file,
}: UpdateRenameFileProps) => {
  /*
   data input handeling
   */
  const [data, setData] = useState<DataTypes>({
    name: file.name,
  });

  const onChangeData = (field: "name", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };

  /*
    validation
    */
  const [valid, setValid] = useState<boolean>(false);
  useEffect(() => {
    if (data.name !== null) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [data]);

  /*
    submit data
    */
  const onSubmit = () => {
    onUpdate(data);
  };
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className="modal-title-custom text-white font-size-16" toggle={onClose}>
        <Translator path="file.renameFile" />
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label htmlFor="update-bookmark" className="mb-2">
              <Translator path="file.file" />
            </Label>
            <Input
              id="update-bookmark"
              type="text"
              name="email"
              maxLength={200}
              value={data.name ?? ""}
              onChange={(e: any) => {
                onChangeData("name", e.target.value);
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="none" onClick={onClose}>
          <Translator path="utils.cancel" />
        </Button>
        <Button color="primary" disabled={!valid} onClick={onSubmit}>
          <Translator path="utils.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateRenameFile;
