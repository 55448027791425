export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  SEARCH = "pills-search",
  FILES = "pills-files",
}
