import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ErrorEnum } from "../../constants/error";
import { UserProfile } from "../../data/models/user";
import { UserService } from "../../data/services/user.service";

interface IUserContext {
  loading: boolean;
  token: string;
  userProfile: UserProfile;
  getUserInformation: () => Promise<void>;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);

  const [token, setToken] = useState<string>("");

  const userService = useMemo(() => {
    return new UserService();
  }, []);

  const getUserInformation = useCallback(async () => {
    try {
      setLoading(true);
      const data = await userService.getUserInformation();
      setUserProfile(data as unknown as UserProfile);
    } catch (error) {
      if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
        return;
      }
      toast.error(t('user.errorGetUser'))
    } finally {
      setLoading(false);
    }
  }, [t, userService]);

  useEffect(() => {
    getUserInformation();
  }, [getUserInformation]);

  const value = useMemo(
    () => ({
      userProfile,
      loading,
      token,
      getUserInformation,
      setToken,
    }),
    [userProfile, token, loading, getUserInformation, setToken],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  return useContext(UserContext);
};

export { UserProvider, useUser };
