import { MouseEvent as MouseEventReact, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledTooltip
} from "reactstrap";
import { File } from "../data/models/file";
import { useFiles } from "../hooks/FileContext/FileContext";
import Translator from "./Translator";
import UpdateRenameFile, { DataTypes } from "./UpdateRenameFile";

interface AttachedFileItemProps {
    attachedFile: File;
}
const AttachedFileItem = ({ attachedFile }: AttachedFileItemProps) => {

    const { selectedFile, setSelectedFile, downloadFile, deleteFile, renameFile } = useFiles()

    const [isOpenRenameFile, setIsOpenRenameFile] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMore = () => setDropdownOpen(!dropdownOpen);

    const iconClassName = () => {
        if (attachedFile.type.includes('pdf')) {
            return 'bx bxs-file-pdf'
        }
        if (attachedFile.type.includes('text')) {
            return 'bx bxs-file-txt'
        }
        if (attachedFile.type.includes('image')) {
            return 'bx bxs-file-image'
        }
        return 'bx bxs-file';
    };

    const onUpdateFile = (data: DataTypes) => {
        if (data.name !== null) {
            renameFile(attachedFile, data.name);
        }
        console.warn('onUpdateFile', 'file name is null!')
    };

    const handleDelete = (event: MouseEventReact<HTMLElement, MouseEvent>, file: File) => {
        deleteFile(file);
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <div className="d-flex justify-content-between ps-3 mb-3">
            <div className="d-flex align-items-center" style={{ maxWidth: 'calc(100% - 6.5rem)' }}>
                <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                        <i className={iconClassName()}></i>
                    </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-14 text-truncate mb-1">
                        {attachedFile.name}
                    </h5>
                    <p className="text-muted font-size-13 mb-0">{attachedFile.type}</p>
                </div>
            </div>
            <div className="d-flex align-content-center">
                <div className="d-flex">
                    <button type="button" id="download-file" className="btn align-items-center justify-content-center" onClick={() => downloadFile(attachedFile)}>
                        <i className="bx bxs-download"></i>
                    </button>
                    <UncontrolledTooltip target="download-file" placement="bottom">
                        <Translator path="utils.download" />
                    </UncontrolledTooltip>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleMore}>
                        <DropdownToggle
                            color="none"
                            className="btn text-muted p-3"
                        >
                            <i className="bx bx-dots-horizontal-rounded"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                                disabled={true}
                                className="dropdown-item d-flex align-items-center justify-content-between"
                                onClick={() => {
                                    setSelectedFile(attachedFile);
                                    setIsOpenRenameFile(true)
                                }}
                            >
                                <Translator path="utils.rename" /> <i className="bx bx-edit-alt text-muted ms-2"></i>
                            </DropdownItem>
                            <DropdownItem
                                className="dropdown-item d-flex align-items-center justify-content-between"
                                onClick={(event) => handleDelete(event, attachedFile)}
                            >
                                <Translator path="utils.delete" /> <i className="bx bx-trash ms-2 text-muted"></i>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            {(isOpenRenameFile && selectedFile) && (
                <UpdateRenameFile
                    isOpen={isOpenRenameFile}
                    onClose={() => setIsOpenRenameFile(false)}
                    onUpdate={onUpdateFile}
                    file={selectedFile}
                />
            )}
        </div>
    );
};

export default AttachedFileItem;