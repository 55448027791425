import classnames from "classnames";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { TABS } from "../../constants/index";
import { useRedux } from "../../hooks";
import { useChats } from "../../hooks/ChatContext/ChatContext";
import { useFiles } from "../../hooks/FileContext/FileContext";
import { useSideMenu } from "../../hooks/SideMenuContext/SideMenuContext";
import Welcome from "./ConversationUser/Welcome";
import ConversationUser from "./ConversationUser/index";
import AddFile from "./Files/AddFile";
import FilesSelected from "./Files/FileSelected";
import Leftbar from "./Leftbar";

interface IndexProps { }

const Index = (props: IndexProps) => {

  const { selectedChat, loadingByChatId, loadChatsByUser, setNullToSelectedChat } = useChats();
  const { selectedFile, loading: loadingFiles } = useFiles();
  const { showSideMenu, setShowSideMenu } = useSideMenu();

  const { useAppSelector } = useRedux();
  const errorData = createSelector(
    (state: any) => state.Layout,
    state => ({
      activeTab: state.activeTab,
    }),
  );
  const { activeTab } = useAppSelector(errorData);

  const [mainContent, setMainContent] = useState<JSX.Element>(<Welcome />)

  useEffect(() => {
    if (activeTab === TABS.FILES) {
      setNullToSelectedChat();
      if ((loadingFiles || selectedFile !== null)) {
        setMainContent(<FilesSelected />);
      } else {
        setMainContent(<AddFile />);
      }
      setShowSideMenu(true);
      return;
    }
    if (activeTab === TABS.SEARCH && (loadingByChatId || selectedChat !== null)) {
      setMainContent(<ConversationUser />);
      return;
    }
    setMainContent(<Welcome />);
  }, [
    activeTab,
    loadingByChatId,
    loadingFiles,
    selectedChat,
    selectedFile,
    setNullToSelectedChat,
    setShowSideMenu,
  ]);

  return (
    <>
      <Leftbar />
      <div
        id="user-chat"
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-main-content-show": !showSideMenu,
        })}
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        <div className="chat-content d-lg-flex">
          <div className="w-100 overflow-hidden position-relative">
            {mainContent}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
