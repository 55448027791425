import HeaderBarBack from "../../../components/HeaderBarBack";
import { useChats } from "../../../hooks/ChatContext/ChatContext";
import { useSideMenu } from "../../../hooks/SideMenuContext/SideMenuContext";
import ChatInputSection from "./ChatInputSection/index";
import Conversation from "./Conversation";

interface IndexProps { }

const Index = (props: IndexProps) => {

  const { setNullToSelectedChat, sendMessage, selectedChat } = useChats();
  const { setShowSideMenu } = useSideMenu()

  const onSend = ({ text }: { text: string }) => {
    if (selectedChat?.chatId && text) {
      sendMessage({
        chatId: selectedChat.chatId,
        content: text
      })
    }
  };

  return (
    <>
      <HeaderBarBack onClick={() => {
        setNullToSelectedChat();
        setShowSideMenu(true)
      }} />
      <Conversation />
      <ChatInputSection onSend={onSend} />
    </>
  );
};

export default Index;
