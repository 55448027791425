import { saveAs } from 'file-saver';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { ErrorEnum } from '../../constants/error';
import { FIVE_SECONDS_IN_MS } from "../../constants/time";
import { File as ModelFile } from "../../data/models/file";
import { FileService } from "../../data/services/file.service";

interface IFileContext {
    deleteFile: (file: ModelFile) => Promise<void>;
    downloading: boolean;
    downloadFile: (file: ModelFile) => void;
    files: ModelFile[];
    loadFilesByUser: (force?: boolean) => Promise<void>;
    loading: boolean;
    selectedFile: ModelFile | null;
    setSelectedFile: (file: ModelFile | null) => void;
    sendFiles: (files: File[]) => Promise<void>;
    renameFile: (file: ModelFile, newName: string) => Promise<void>;
}

interface FileProviderProps {
    children: ReactNode;
}

const FileContext = createContext<IFileContext>({} as IFileContext);

const FileProvider: React.FC<FileProviderProps> = ({ children }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [files, setFiles] = useState<ModelFile[]>([]);
    const [lastSyncFiles, setLastSyncFiles] = useState<Date>(new Date('1980-01-01 00:00:00'));
    const [selectedFile, setSelectedFile] = useState<ModelFile | null>(null);

    const fileService = useMemo(() => {
        return new FileService();
    }, []);

    const loadFilesByUser = useCallback(async (force: boolean = false) => {
        try {
            if (!force && (new Date().getTime() - lastSyncFiles.getTime()) < FIVE_SECONDS_IN_MS) {
                return;
            }
            setLoading(true);
            const { userDatasources } = await fileService.getFilesByUser();
            setFiles(userDatasources.toSorted((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                if (dateA.getTime() > dateB.getTime()) return -1;
                if (dateA.getTime() < dateB.getTime()) return 1;
                return 0;
            }));
            setLastSyncFiles(new Date());
        } catch (error) {
            if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
                return;
            }
            toast.error(t('file.errorGetFiles'));
        } finally {
            setLoading(false);
        }
    }, [fileService, lastSyncFiles, t]);

    const downloadFile = useCallback(async ({ dataSourceId, name }: ModelFile) => {
        try {
            setDownloading(true)
            const { data } = await fileService.getDownloadUrl(dataSourceId);
            const uint8Array = new Uint8Array(data);
            const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
            saveAs(blob, name);
        } finally {
            setDownloading(false)
        }
    }, [fileService]);

    const deleteFile = useCallback(async ({ dataSourceId }: ModelFile) => {
        try {
            setLoading(true);
            await fileService.removeFile(dataSourceId);
            toast.success(t('file.fileDeleted'));
            setFiles(prev => {
                return prev.filter(file => file.dataSourceId !== dataSourceId);
            })
        } catch (error) {
            if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
                return;
            }
            toast.error(t('file.errorDeleteFile'));
        } finally {
            setLoading(false);
        }
    }, [fileService, t]);

    const sendFiles = useCallback(async (filesToUpload: File[]) => {
        try {
            if (filesToUpload.length === 0) {
                toast.error(t('file.noFilesSelected'));
                return;
            }
            setLoading(true);
            const fileUploaded = await fileService.sendFile(filesToUpload);

            setFiles(prev => {
                return [fileUploaded, ...prev];
            });

            toast.success(t('file.fileSent'));
        } catch (error) {
            if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
                return;
            }
            toast.error(t('file.errorSendFile'));
        } finally {
            setLoading(false);
        }
    }, [fileService, t]);

    const renameFile = useCallback(async ({ dataSourceId }: ModelFile, newName: string) => {
        try {
            setLoading(true);
            await fileService.renameFile(dataSourceId, newName);
            toast.success(t('file.fileRenamed'));
        } catch (error) {
            if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
                return;
            }
            toast.error(t('file.errorRenameFile'));
        } finally {
            setLoading(false);
        }
    }, [fileService, t]);

    useEffect(() => {
        setTimeout(() => {
            loadFilesByUser();
        }, 500);
    }, []);

    const value = useMemo(
        () => ({
            deleteFile,
            downloadFile,
            downloading,
            files,
            loadFilesByUser,
            loading,
            selectedFile,
            setSelectedFile,
            sendFiles,
            renameFile,
        }),
        [deleteFile, downloadFile, downloading, files, loadFilesByUser, loading, renameFile, selectedFile, sendFiles],
    );

    return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};

const useFiles = () => {
    return useContext(FileContext);
};

export { FileProvider, useFiles };
