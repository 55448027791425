
import Picker from "emoji-picker-react";
import { Button, UncontrolledTooltip } from "reactstrap";
import Translator from "../../../../components/Translator";
import { useSideMenu } from "../../../../hooks/SideMenuContext/SideMenuContext";

interface StartButtonsProps {
  onToggle: () => void;
  onEmojiClick: any;
  emojiPicker: any;
  setEmojiPicker: any;
}

const StartButtons = ({ onToggle, onEmojiClick, emojiPicker, setEmojiPicker }: StartButtonsProps) => {

  const { isMobile } = useSideMenu();

  return (
    <div className="chat-input-links me-md-2">
      {!isMobile &&
        <>
          <div className="links-list-item" id="more-menu">
            <Button
              type="button"
              className="btn btn-link text-decoration-none btn-lg waves-effect"
              onClick={onToggle}
              color="none"
            >
              <i className="bx bxs-file-plus align-middle"></i>
            </Button>
          </div>
          <UncontrolledTooltip target="more-menu" placement="top">
            <Translator path="file.addFile" />
          </UncontrolledTooltip>
        </>
      }
      {emojiPicker && <Picker onEmojiClick={onEmojiClick} width={350} height={382} />}
      <div className="links-list-item" id="emoji">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect emoji-btn"
          id="emoji-btn"
          color="none"
          onClick={() => setEmojiPicker(!emojiPicker)}
        >
          <i className="bx bx-smile align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default StartButtons;
