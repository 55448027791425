import { Col, Row } from "reactstrap";
import logoComplete from '../../../assets/images/logo-complete.svg';
import Translator from "../../../components/Translator";
import SelectFilesToUpload from "./SelectFilesToUpload";

const AddFile = () => {
  return (
    <div className="chat-main-content-section">
      <Row className="w-100 justify-content-center">
        <Col xxl={7} md={9}>
          <div className="p-2 text-center">
            <div className="avatar-xl mx-auto mb-4">
              <img alt="logo" src={logoComplete} />
            </div>
            <h6>
              <Translator path="file.addFile" />
            </h6>
            <p className="text-muted mb-4">
              <Translator path="file.insertFile" />
            </p>
            <div className="text-center px-2 position-relative">
              <SelectFilesToUpload />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddFile;
