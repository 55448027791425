import enUsTranslations from './en-us';
import ptBrTranslations from './pt-br';
import { ITranslation } from './translation.type';

export type LanguageAvaiableType = {
    'pt-BR': ITranslation,
    'en-US': ITranslation
}

const languages: LanguageAvaiableType = {
    'pt-BR': ptBrTranslations,
    'en-US': enUsTranslations
}
export default languages;