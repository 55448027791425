import classnames from "classnames";
import { Link } from "react-router-dom";
import { Chat } from "../../../data/models/chat";
import { useChats } from "../../../hooks/ChatContext/ChatContext";

interface ChannelProps {
  chat: Chat;
  onSelectChat: (chatId: string) => void;
}
const ChatChannel = ({ chat, onSelectChat }: ChannelProps) => {

  const { selectedChat } = useChats();

  const onClick = () => {
    onSelectChat(chat.chatId);
  };

  return (
    <button type="button" className={classnames({ 'active': selectedChat?.chatId === chat.chatId, 'btn': true })} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": false })}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              #
            </span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-truncate mb-0">{chat.title}</p>
          </div>
        </div>
      </Link>
    </button>
  );
};

export default ChatChannel;
