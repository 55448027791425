import { File as ModelFile } from "../models/file";
import { Group } from "../models/group";
import * as api from "./api.service";

export interface IGetFile {
    userDatasources: ModelFile[],
    groupsWithDocuments: Group[],
}

export interface IDownloadFile {
    type: string,
    data: number[]
}

export class FileService {
    baseUrl = "data-sources";

    async getFilesByUser() {
        const response = await api.get<IGetFile>(this.baseUrl);

        return response;
    }

    async sendFile(files: File[], type: string = '', data: string = '', name: string = '', groupId: string = '', dataUrl: string = '') {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('data', data);
        formData.append('name', name);
        formData.append('groupId', groupId);
        formData.append('dataUrl', dataUrl);

        for (const element of files) {
            formData.append('document', element, element.name);
        }

        try {
            const response = await api.post(this.baseUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    async getDownloadUrl(dataSourceId: string) {
        const response = await api.get<IDownloadFile>(`${this.baseUrl}/download/${dataSourceId}/`);
        return response;
    }

    async removeFile(dataSourceId: string) {
        const response = await api.remove(`${this.baseUrl}/${dataSourceId}`);

        return response;
    }

    async renameFile(dataSourceId: string, newName: string) {
        //TODO: implement rename file
        console.log('rename file', dataSourceId, newName);
    }
}
