import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import languages from './locales'

const i18nConfig = {
    resources: languages,
    fallbackLng: 'pt-BR',
    defaultNS: 'translations'
}

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig)

export default i18n;