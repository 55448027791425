import { useKeycloak } from "@react-keycloak/web";
import Loader from "../components/Loader";

const AuthProtected = (props: any) => {
  const { initialized, keycloak } = useKeycloak();

  if (initialized && !keycloak.authenticated) {
    keycloak.login();
  }

  if (keycloak.authenticated) {
    return <>{props.children}</>;
  }
  return <Loader />;
};

export { AuthProtected };
