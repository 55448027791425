import { useKeycloak } from "@react-keycloak/web";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Socket, io } from 'socket.io-client';
import { ErrorEnum } from "../../constants/error";
import { FIVE_SECONDS_IN_MS, ONE_SECOND_IN_MS } from '../../constants/time';
import { Chat, ChatMessage } from "../../data/models/chat";
import { ChatService, ISendMessage } from "../../data/services/chat.service";
import { useFiles } from "../FileContext/FileContext";
import { useUser } from "../UserContext/UserContext";

type ChatMessageType =
  | { chatId: string; dataSourceIds: string[] }
  | { chatId: string; token: string };


interface IChatContext {
  loading: boolean;
  loadingByChatId: boolean;
  chats: Chat[];
  selectedChat: Chat | null;
  searching: boolean;
  responding: boolean;
  setNullToSelectedChat: () => void;
  loadChatsByUser: (force?: boolean) => Promise<void>;
  loadChatById: (chatId: string) => Promise<void>;
  sendMessage: (data: ISendMessage) => Promise<void>;
}

interface ChatProviderProps {
  children: ReactNode;
}

const ChatContext = createContext<IChatContext>({} as IChatContext);

const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const { userProfile: { userId } } = useUser();
  const { files } = useFiles();
  const { keycloak: { token } } = useKeycloak();

  const ACK_CHAT = "chats";

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingByChatId, setLoadingByChatId] = useState<boolean>(false);
  const [lastSyncChats, setLastSyncChats] = useState<Date>(new Date('1980-01-01 00:00:00'));
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [chats, setChats] = useState<Chat[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [responding, setResponding] = useState<boolean>(false);
  const [socket, setSocket] = useState<Socket>()

  let startReceiveMessageTimestamp = Date.now();

  const chatService = useMemo(() => {
    return new ChatService();
  }, []);

  const setNullToSelectedChat = () => {
    setSelectedChat(null);
  };

  const loadChatsByUser = useCallback(async (force: boolean = false) => {
    try {
      if (!force && (new Date().getTime() - lastSyncChats.getTime()) < FIVE_SECONDS_IN_MS) {
        return;
      }

      setLoading(true);
      const data = await chatService.getChatsByUser();
      setChats(data);
      setLastSyncChats(new Date());
    } catch (error) {
      if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
        return;
      }
      toast.error(t('chats.searchingChats'));
    } finally {
      setLoading(false);
    }
  }, [chatService, lastSyncChats, t]);

  const loadChatById = useCallback(async (chatId: string) => {
    try {
      if (chatId) {
        setLoadingByChatId(true);
        const chat = await chatService.getChatById(chatId);
        setSelectedChat(chat);
      }
    } catch (error) {
      if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
        return;
      }
      toast.error(t('chats.searchingChat'))
    } finally {
      setLoadingByChatId(false);
    }
  }, [chatService, t]);

  const sendMessage = useCallback(async (data: ISendMessage) => {
    try {
      setSearching(true);
      socket?.emit(ACK_CHAT, data);
      const newMessage: ChatMessage = {
        ...data,
        messageId: Date.now().toString(),
        userId,
        messageDataSources: [],
        createdAt: new Date(),
      };
      if (selectedChat) {
        selectedChat.messages ??= [];
        setSelectedChat((prev) => {
          if (prev) {
            return {
              ...prev,
              messages: [...(prev.messages ?? []), newMessage],
            };
          }
          return prev;
        })
      } else {
        setSelectedChat({
          chatId: data.chatId,
          title: data.content,
          messages: [newMessage],
          userId,
          groupId: null,
          createdAt: new Date(),
        });
      }

      if (!chats.some(x => x.chatId === data.chatId)) {
        setChats(prev => [{
          chatId: data.chatId,
          title: data.content,
          messages: [newMessage],
          userId,
          groupId: null,
          createdAt: new Date(),
        }, ...prev])
      }
    } catch (error) {
      if (error instanceof Error && error.message === ErrorEnum.SERVER_OFFLINE) {
        return;
      }
      toast.error(t('chats.searchingChat'))
      setSearching(false);
    }
  }, [chats, selectedChat, socket, t, userId]);

  const handleUpdateNewMessageOnSelectedChatOnListener = useCallback((prev: Chat | null, message: ChatMessageType): Chat | null => {
    if (!prev) {
      return prev;
    }
    let messageId = Date.now().toString();
    if ('dataSourceIds' in message) {
      message.dataSourceIds = message.dataSourceIds.filter((x, i) => message.dataSourceIds.indexOf(x) === i);
      const messageDataSources: ChatMessage['messageDataSources'] = message.dataSourceIds.map(dataSourceId => {
        const file = files.find(file => file.dataSourceId === dataSourceId);
        const fileName = file?.name ?? (dataSourceId + ' (' + t('utils.deleted') + ')')
        return {
          messageId,
          dataSourceId,
          dataSourceName: fileName,
          createdAt: new Date(),
        }
      })
      return {
        ...prev, messages: [...(prev.messages || []),
        {
          chatId: message.chatId,
          content: '',
          userId: null,
          messageId,
          createdAt: new Date(),
          messageDataSources
        }]
      }
    }
    return {
      ...prev, messages: [...(prev.messages || []),
      {
        chatId: message.chatId,
        content: message.token,
        userId: null,
        messageId,
        createdAt: new Date(),
        messageDataSources: []
      }]
    }
  }, [files, t])

  const handleUpdateContinueMessageOnSelectedChat = useCallback((prev: Chat | null, message: ChatMessageType): Chat | null => {
    if (!prev) {
      return prev;
    }
    const lastMessageFromSelectedChat = prev?.messages?.slice(-1)[0] as ChatMessage;
    if ('dataSourceIds' in message) {
      message.dataSourceIds = message.dataSourceIds.filter((x, i) => message.dataSourceIds.indexOf(x) === i);
      const messageDataSources: ChatMessage['messageDataSources'] = message.dataSourceIds.map(dataSourceId => {
        const file = files.find(file => file.dataSourceId === dataSourceId);
        const fileName = file?.name ?? (dataSourceId + ' (' + t('utils.deleted') + ')')
        return {
          messageId: lastMessageFromSelectedChat.messageId,
          dataSourceId,
          dataSourceName: fileName,
          createdAt: new Date(),
        }
      })
      return {
        ...prev, messages: [...(prev.messages?.slice(0, -1) || []),
        {
          ...lastMessageFromSelectedChat,
          messageDataSources,
        }]
      }
    }
    return {
      ...prev, messages: [...(prev.messages?.slice(0, -1) || []),
      {
        ...lastMessageFromSelectedChat,
        content: lastMessageFromSelectedChat?.content.concat(message.token) ?? 'Error',
      }]
    }
  }, [files, t])

  const handleMessageListener = useCallback((message: ChatMessageType) => {
    if (message.chatId !== selectedChat?.chatId) {
      return;
    }

    const currentTimestamp = Date.now();
    const timeSinceLastReceipt = currentTimestamp - startReceiveMessageTimestamp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    startReceiveMessageTimestamp = currentTimestamp;

    if (timeSinceLastReceipt > (2 * ONE_SECOND_IN_MS)) {
      //Nova mensagem
      setResponding(false);
      setSelectedChat((prev) => handleUpdateNewMessageOnSelectedChatOnListener(prev, message));
    } else {
      //Continuar mensagem
      setResponding(true);
      setSelectedChat(prev => handleUpdateContinueMessageOnSelectedChat(prev, message));
    }

    setSearching(false);
  }, [selectedChat, files]);

  useEffect(() => {
    loadChatsByUser();
  }, []);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL}/gateway?token=${token}`);
    setSocket(socket);
    return () => {
      socket?.disconnect();
    }
  }, [token])

  useEffect(() => {
    if (selectedChat) {
      socket?.on(selectedChat?.chatId, handleMessageListener);
      return () => {
        socket?.off(selectedChat?.chatId, handleMessageListener);
      }
    }
  }, [socket, selectedChat, handleMessageListener])

  useEffect(() => {
    //handle responding
    return () => {
      setResponding(false);
    }
  }, [selectedChat]);

  const value = useMemo(
    () => ({
      chats,
      loadChatById,
      loadChatsByUser,
      loading,
      loadingByChatId,
      selectedChat,
      sendMessage,
      setNullToSelectedChat,
      searching,
      responding,
    }),
    [chats, loadChatById, loadChatsByUser, loading, loadingByChatId, selectedChat, sendMessage, searching, responding],
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

const useChats = () => {
  return useContext(ChatContext);
};

export { ChatProvider, useChats };
