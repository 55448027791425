import Translator from "../../../components/Translator";

const Typing = () => {
    return (
        <p className="mb-0">
            <Translator path="chat.searching" />
            <span className="animate-typing">
                <span className="dot mx-1"></span>
                <span className="dot me-1"></span>
                <span className="dot"></span>
            </span>
        </p>
    );
};
export default Typing;