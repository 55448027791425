import {
  UncontrolledTooltip
} from "reactstrap";
import { useFiles } from "../hooks/FileContext/FileContext";
import AppSimpleBar from "./AppSimpleBar";
import AttachedFileItem from "./AttachedFileItem";
import Loader from "./Loader";
import NewFileButton from "./NewFileButton";
import Translator from "./Translator";

interface AttachedFilesProps { }

const AttachedFiles = (props: AttachedFilesProps) => {
  const { files, loading, downloading, setSelectedFile } = useFiles()

  const navigateToNewFile = () => {
    setSelectedFile(null);
  }

  return (
    <div id="list-files">
      <div>
        <div className="px-4 pt-4">
          <div className="flex-grow-1">
            <h4 className="mb-4">
              <Translator path="file.file" />
            </h4>
          </div>
          <div>
            <div id="new-file">
              <NewFileButton onClick={navigateToNewFile} />
            </div>
            <UncontrolledTooltip target="new-file" placement="bottom">
              <Translator path="file.addFile" />
            </UncontrolledTooltip>
          </div>
        </div>
        <AppSimpleBar className="chat-room-list">
          {(loading || downloading) && <Loader />}
          <>
            <div className="d-flex align-items-center px-4 mt-5 mb-2">
              <div className="flex-grow-1">
                <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                  <Translator path="file.yours" />
                </h4>
              </div>
            </div>
            <div className="chat-message-list">
              <ul className="list-unstyled chat-list chat-user-list mb-3">
                {files.length > 0
                  ? files.map((file) => (
                    <AttachedFileItem attachedFile={file} key={file.dataSourceId} />
                  ))
                  :
                  <div className="m-4">
                    <Translator path="file.noFiles" />
                  </div>
                }
              </ul>
            </div>
          </>
        </AppSimpleBar>
      </div>
    </div>
  );
};
export default AttachedFiles;
