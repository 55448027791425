import { useSideMenu } from "../hooks/SideMenuContext/SideMenuContext";
import Translator from "./Translator";
import './styles.css';

interface NewFileButtonProps {
    onClick: () => void;
}
const NewFileButton = ({ onClick }: NewFileButtonProps) => {
    const { isMobile } = useSideMenu();

    return (
        <button
            type="button"
            onClick={onClick}
            disabled={isMobile}
            className="btn btn-primary w-lg border-radius-12"
            style={{ width: '100%' }}
        >
            <i className="bx bx-plus"></i> <Translator path="file.addFile" />
        </button>
    );
};

export default NewFileButton;
