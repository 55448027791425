import { TabContent, TabPane } from "reactstrap";
import { createSelector } from "reselect";
import AttachedFiles from "../../components/AttachedFiles";
import { TABS } from "../../constants/index";
import { useRedux } from "../../hooks/index";
import ListChats from "./Chats/index";

interface LeftbarProps { }
const Leftbar = (props: LeftbarProps) => {

  const { useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Layout,
    state => ({
      activeTab: state.activeTab,
    }),
  );
  const { activeTab } = useAppSelector(errorData);

  return (
    <>
      {/* start chat-leftsidebar */}
      <div className="chat-leftsidebar">
        <TabContent activeTab={activeTab}>
          {/* Start Profile tab-pane */}

          <TabPane tabId={TABS.FILES} role="tabpanel">
            <AttachedFiles />
          </TabPane>

          <TabPane tabId={TABS.SEARCH} role="tabpanel">
            <ListChats />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Leftbar;
