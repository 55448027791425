import { Link } from "react-router-dom";

interface HeaderBarBackProps {
    onClick: () => void
}

const HeaderBarBack = ({ onClick }: HeaderBarBackProps) => {
    return (
        <div className="d-flex align-items-center p-3 p-lg-4 user-chat-topbar">
            <div className="flex-shrink-0 d-block d-lg-none me-2">
                <Link
                    to="#"
                    onClick={onClick}
                    className="user-chat-remove font-size-24 p-2"
                >
                    <i className="bx bx-chevron-left align-middle"></i>
                </Link>
            </div>
        </div>
    );
};

export default HeaderBarBack;
