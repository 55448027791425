import { useCallback, useEffect, useRef } from "react";
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import { useChats } from "../../../hooks/ChatContext/ChatContext";
import Message from "./Message";
// import Day from "./Day";

interface ConversationProps { }
const Conversation = (props: ConversationProps) => {

  const { selectedChat, loadingByChatId } = useChats();

  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref?.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref?.current) {
      ref.current.recalculate();
    }
  }, []);

  useEffect(() => {
    if (selectedChat?.messages) {
      scrollElement();
    }
  }, [selectedChat, scrollElement]);

  const messages = useCallback(() => {
    if (!selectedChat?.messages) {
      return <></>;
    }
    return selectedChat.messages.map((message) => <Message message={message} key={message.messageId} />)
  }, [selectedChat]);

  return (
    <AppSimpleBar
      scrollRef={ref}
      className="chat-conversation p-3 p-lg-4 positin-relative"
    >
      {loadingByChatId && <Loader />}
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {/* <Day /> */}
        {messages()}
      </ul>
    </AppSimpleBar>
  );
};

export default Conversation;
