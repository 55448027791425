import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import logo from "../../../assets/images/logo.svg";
import imagePlaceholder from "../../../assets/images/users/user-dummy-img.jpg";
import Translator from "../../../components/Translator";
import { ChatMessage } from "../../../data/models/chat";
import { useFiles } from "../../../hooks/FileContext/FileContext";
import { useUser } from "../../../hooks/UserContext/UserContext";
import { formateDate } from "../../../utils";
import './style.css';

interface MessageProps {
  message: ChatMessage;
}
const Message = ({ message }: MessageProps) => {

  const { userProfile } = useUser();
  const { files, downloadFile, downloading } = useFiles();
  const { t } = useTranslation();

  const isFromMe = !!message.userId;

  const fullName = 'PMM Bible';

  const myProfile = userProfile.imageProfile ?? imagePlaceholder;
  const chatUserprofile = logo ?? imagePlaceholder;
  const date = formateDate(message.createdAt, "hh:mmaaa");

  const parseContent = (content: string) => {
    const parts = content.split(/(\*\*[^*]+\*\*|_[^_]+_)/);

    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else if (part.startsWith('_') && part.endsWith('_')) {
        return <em key={index}>{part.slice(1, -1)}</em>;
      } else if (part.includes('\n')) {
        return part.split('\n').map((line, subIndex) => (
          <React.Fragment key={`${index}-${subIndex}`}>
            {line}
            <br />
          </React.Fragment>
        ));
      }
      return part;
    });
  };

  return (
    <li
      className={classnames(
        "chat-list",
        { right: isFromMe },
      )}
    >
      <div className="conversation-list">
        <div className="user-chat-content">
          <div className={classnames("d-flex", "mb-2", { 'justify-content-end': isFromMe })}>
            <div className="chat-avatar">
              <img src={isFromMe ? myProfile : chatUserprofile} alt="" />
            </div>
            <div className="conversation-name">
              {isFromMe ? (
                <>
                  <span className="me-1 text-success">
                    <i className="bx"></i>
                  </span>
                  <small className="text-muted mb-0 me-2">{date}</small>
                  <span className="user-name-font">
                    <Translator path="chat.you" />
                  </span>
                </>
              ) : (
                <span className="user-name-font">
                  {fullName}
                  <small className="text-muted mb-0 ms-2">
                    {date}
                  </small>
                </span>
              )}
            </div>
          </div>
          <p className="mx-2 mb-2 ctext-content message-font">
            {parseContent(message.content)}
          </p>
          {(!isFromMe && message.messageDataSources.length > 0) &&
            <div className="d-flex gap-2 align-content-center align-items-center text-muted flex-wrap">
              <Translator path="chat.referencesUsed" />:
              {message.messageDataSources.map(messageDataSource => {
                const file = files.find(file => file.dataSourceId === messageDataSource.dataSourceId);
                const fileName = file?.name ?? ((messageDataSource.dataSourceName ?? messageDataSource.dataSourceId.slice(0, 6)) + ' (' + t('utils.deleted') + ')')
                return <>
                  <button
                    id={"btn-download-" + messageDataSource.dataSourceId}
                    key={messageDataSource.dataSourceId}
                    type="button"
                    className="btn btn-primary btn-sm download-file-btn"
                    onClick={() => {
                      if (file) {
                        downloadFile(file)
                      }
                    }}
                    disabled={downloading || !file}
                  >
                    <span className={classnames({ 'text-line-through': !file })}>
                      {fileName}
                    </span>
                    <i className="bx bxs-download mx-2"></i>
                    {downloading && <i className="bx bx-loader-alt bx-spin"></i>}
                  </button>
                  <UncontrolledTooltip target={"btn-download-" + messageDataSource.dataSourceId}>
                    <Translator path="utils.download" /> {fileName}
                  </UncontrolledTooltip>
                </>
              })}
            </div >
          }
        </div>
      </div>
    </li>
  );
};

export default Message;
