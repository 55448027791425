import Translator from "./Translator";
import './styles.css'

interface NewSearchButtonProps {
    onClick: () => void;
}
const NewSearchButton = ({ onClick }: NewSearchButtonProps) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="btn btn-primary w-lg border-radius-12"
            style={{ width: '100%' }}
        >
            <i className="bx bx-plus"></i> <Translator path="chats.newSearch" />
        </button>
    );
};

export default NewSearchButton;
