import Translator from "../../../components/Translator";
import { Chat } from "../../../data/models/chat";
import ChatChannel from "./ChatChannel";

interface ChatsProps {
  chats: Array<Chat>;
  onSelectChat: (chatId: string) => void;
}
const Chats = ({
  chats,
  onSelectChat,
}: ChatsProps) => {

  return (
    <>
      <div className="d-flex align-items-center px-4 mt-5 mb-2">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">
            <Translator path="chats.yoursChats" />
          </h4>
        </div>
      </div>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list mb-3">
          {(chats || []).map((chat: Chat, key: number) => (
            <ChatChannel
              chat={chat}
              key={key}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Chats;
