import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";

interface ISideMenuContext {
    showSideMenu: boolean;
    isMobile: boolean;
    setShowSideMenu: (show: boolean) => void;
}

interface SideMenuProviderProps {
    children: ReactNode;
}

const SideMenuContext = createContext<ISideMenuContext>({} as ISideMenuContext);

const SideMenuProvider: React.FC<SideMenuProviderProps> = ({ children }) => {

    const [showSideMenu, setShowSideMenu] = useState<boolean>(true)
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const handleSetShowSideMenu = useCallback((show: boolean) => {
        if (isMobile) {
            setShowSideMenu(show);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 992);
            setShowSideMenu(window.innerWidth >= 992);
        });
        return () => {
            window.removeEventListener('resize', () => { });
        };
    }, [])

    useMemo(() => {
        if (window.innerWidth < 992) {
            setIsMobile(true);
        }
    }, []);

    const value = useMemo(
        () => ({
            showSideMenu,
            setShowSideMenu: handleSetShowSideMenu,
            isMobile
        }),
        [handleSetShowSideMenu, isMobile, showSideMenu],
    );

    return <SideMenuContext.Provider value={value}>{children}</SideMenuContext.Provider>;
};

const useSideMenu = () => {
    return useContext(SideMenuContext);
};

export { SideMenuProvider, useSideMenu };
