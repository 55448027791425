import { useEffect, useState } from "react";
import { Alert, Form } from "reactstrap";
import Translator from "../../../../components/Translator";
import { TABS } from "../../../../constants";
import { useRedux } from "../../../../hooks";
import { useChats } from "../../../../hooks/ChatContext/ChatContext";
import { changeTab } from "../../../../redux/actions";
import Typing from "../Typing";
import EndButtons from "./EndButtons";
import InputSection from "./InputSection";
import StartButtons from "./StartButtons";

interface IndexProps {
  onSend: (data: any) => void;
}
const Index = ({
  onSend,
}: IndexProps) => {

  const { searching } = useChats();
  const { dispatch } = useRedux();

  const onToggle = () => {
    dispatch(changeTab(TABS.FILES));
    setEmojiArray([]);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const onChangeText = (value: string) => {
    setText(value);
  };

  /*
  images
  */
  const [images, setImages] = useState<Array<any> | null | undefined>();
  const onSelectImages = (images: Array<any>) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    setFiles(files);
  };
  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);

  // emoji picker 
  const [emojiArray, setEmojiArray] = useState<any[]>([]);
  const [emojiPicker, setEmojiPicker] = useState<boolean>(false);
  const onEmojiClick = (event: any) => {
    setEmojiArray([...emojiArray, event.emoji]);
    setText(text + event.emoji);
  };


  // Submit Message
  const onSubmit = () => {
    let data: any = {};
    if (text) {
      data["text"] = text;
    }
    if (images?.length) {
      const imgs = (images || []).map((i: any, key: number) => {
        const src = URL.createObjectURL(i);
        return {
          id: key + 1,
          downloadLink: src,
        };
      });
      data["image"] = imgs;
    }

    if (files?.length) {
      const fs = (files || []).map((f: any, key: number) => {
        const src = URL.createObjectURL(f);
        return {
          id: key + 1,
          name: f.name,
          downloadLink: src,
          desc: f.size,
        };
      });
      data["attachments"] = fs;
    }

    setText("");
    setImages(null);
    setFiles(null);
    setEmojiPicker(false);
    onSend(data);
  };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
  };

  return (
    <>
      <div className="mx-3">
        {searching && <Typing />}
      </div>
      <div className="chat-input-section p-3 p-lg-4">
        <Form
          id="chatinput-form"
          onSubmit={(e: any) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="row g-0 align-items-center">
            <div className="col-auto">
              <StartButtons
                onToggle={onToggle}
                onEmojiClick={onEmojiClick}
                setEmojiPicker={setEmojiPicker}
                emojiPicker={emojiPicker}
              />
            </div>
            <div className="col">
              <InputSection value={text} onChange={onChangeText} />
            </div>
            <div className="col-auto">
              <EndButtons onSubmit={onSubmit} disabled={disabled} />
            </div>
          </div>
        </Form>
        {(images?.length) || (files?.length) ? (
          <Alert
            isOpen={true}
            toggle={onClearMedia}
            color="secondary"
            className="alert-dismiss-custom rounded-pill font-size-12 mb-1 selected-media"
            closeClassName="selected-media-close"
          >
            <p className="me-2 mb-0">
              {images && !files && <Translator path="chat.youHaveSelectedImages" parameters={{ count: images.length }} />}
              {files && !images && <Translator path="chat.youHaveSelectedFiles" parameters={{ count: files.length }} />}
              {files &&
                images &&
                <Translator path="chat.youHaveSelectedFilesAndImages" parameters={{ countFiles: files.length, countImages: images.length }} />
              }
            </p>
          </Alert>
        ) : null}
      </div>
    </>
  );
};

export default Index;
