import { UncontrolledTooltip } from "reactstrap";
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import NewSearchButton from "../../../components/NewSearchButton";
import Translator from "../../../components/Translator";
import { useChats } from "../../../hooks/ChatContext/ChatContext";
import { useSideMenu } from "../../../hooks/SideMenuContext/SideMenuContext";
import Chats from "./Chats";

interface IndexProps { }
const Index = (props: IndexProps) => {

  const { chats, selectedChat, loadChatById, loading, loadingByChatId, setNullToSelectedChat } = useChats();
  const { setShowSideMenu } = useSideMenu();

  const navigateToNewSearch = () => {
    setNullToSelectedChat();
    setShowSideMenu(false);
  }

  const handleSelectChat = (chatId: string) => {
    if (selectedChat?.chatId !== chatId) {
      loadChatById(chatId).then(() => {
        setShowSideMenu(false);
      });
    }
  };

  return (
    <div>
      <div className="px-4 pt-4">
        <div className="flex-grow-1">
          <h4 className="mb-4">
            <Translator path="chats.search" />
          </h4>
        </div>
        <div>
          <div id="new-chat">
            <NewSearchButton onClick={navigateToNewSearch} />
          </div>
          <UncontrolledTooltip target="new-chat" placement="bottom">
            <Translator path="chats.newSearch" />
          </UncontrolledTooltip>
        </div>
      </div>
      <AppSimpleBar className="chat-room-list">
        {(loading || loadingByChatId) && <Loader />}
        <Chats
          chats={chats}
          onSelectChat={handleSelectChat}
        />
      </AppSimpleBar>
    </div>
  );
};

export default Index;
