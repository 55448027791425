import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import Translator from "../../../components/Translator";
import { useFiles } from "../../../hooks/FileContext/FileContext";

interface ISelectFilesToUploadProps { }

const SelectFilesToUpload = (props: ISelectFilesToUploadProps) => {
    const { sendFiles } = useFiles();
    const { t } = useTranslation()

    const [fileToUpload, setFileToUpload] = useState<File[]>([]);

    const handleSubmit = () => {
        sendFiles(fileToUpload).then(() => {
            setFileToUpload([]);
        });
    }

    return (
        <div>
            <div className="d-flex border-radius-12">
                <Label htmlFor="filetoupload-input"
                    className="form-label btn-primary p-0 m-0 justify-content-center align-content-center"
                    style={{ width: '15rem', color: 'white', borderRadius: '12px 0 0 12px' }}>
                    <Translator path="file.chooseFile" />
                </Label>
                <Input
                    id="filetoupload-input"
                    className="d-none"
                    onChange={(value) => {
                        const files = Array.from(value?.target?.files as FileList);
                        if (files.some(file => file.type !== 'application/pdf')) {
                            toast.warn(t('file.onlyPDFFilesAllowed'));
                            return;
                        }
                        setFileToUpload(files);
                    }}
                    type="file"
                    accept="application/pdf"
                    multiple={false}
                />
                <Input
                    disabled={fileToUpload.length > 0}
                    contentEditable={false}
                    className=""
                    readOnly={true}
                    placeholder={t('file.noFilesSelected')}
                    value={fileToUpload.map(f => f.name).join(', ')}
                    onClick={() =>
                        document.getElementById('filetoupload-input')?.click()
                    }
                    style={{ borderRadius: '0 12px 12px 0', border: '1px solid #ced4da' }}
                />
            </div>
            <div className="mt-4 d-flex flex-column">
                <button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary w-lg border-radius-12 mt-4 w-100"
                    disabled={fileToUpload.length === 0}
                >
                    <Translator path="file.addFile" />
                </button>
                <button
                    type="button"
                    onClick={() => setFileToUpload([])}
                    className="btn btn-outline-primary w-lg border-radius-12 mt-4 w-100"
                >
                    <Translator path="utils.cancel" />
                </button>
            </div>
        </div>
    )
}
export default SelectFilesToUpload;