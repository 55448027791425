import { ITranslation } from "./translation.type";

const ptBrTranslations: ITranslation = {
  translations: {
    chats: {
      search: "Pesquisa",
      newSearch: "Nova pesquisa",
      yoursChats: "Seus chats",
      searchingChat: "Ocorreu alum erro ao buscar o chat",
      searchingChats: "Ocorreu algum erro ao buscar os chats",
    },
    chat: {
      you: "Você",
      searching: "buscando",
      attachFile: "Anexar arquivo",
      referencesUsed: "Referências usadas",
      send: "Enviar",
      youHaveSelectedImages: "Você selecionou {{count}} imagens",
      youHaveSelectedFiles: "Você selecionou {{count}} arquivos",
      youHaveSelectedFilesAndImages:
        "Você selecionou {{countFiles}} arquivos e {{countImages}} imagens",
      typeSearchHere: "Digite sua pesquisa aqui",
    },
    menu: {
      logout: "Sair",
      search: "Pesquisar",
      files: "Arquivos",
    },
    welcome: {
      findInformation:
        "Encontre as informações que você precisa sobre {{companyName}}.",
      enterYourQuestionBelow: "Obtenha informações instantâneas sobre produtos, preços, concorrentes e muito mais",
      writeYourSearch: "Pergunte-me qualquer coisa!",
      noResults: "Sem resultados",
      errorFetchSuggestions: "Ocorreu um erro ao buscar as sugestões",
    },
    file: {
      addFile: "Adicionar arquivo",
      file: "Arquivo",
      errorGetFiles: "Ocorreu um erro ao buscar os arquivos",
      noFiles: "Nenhum arquivo encontrado",
      yours: "Seus",
      fileDeleted: "Arquivo deletado com sucesso!",
      errorDeleteFile: "Ocorreu um erro ao deletar o arquivo",
      insertFile:
        "Insira um arquivo para fornecer mais respostas precisas para você e qualquer pessoa que tenha acesso.",
      renameFile: "Renomear arquivo",
      fileSent: "Arquivo enviado com sucesso!",
      errorSendFile: "Ocorreu um erro ao enviar o arquivo",
      fileRenamed: "Arquivo renomeado com sucesso!",
      errorRenameFile: "Ocorreu um erro ao renomear o arquivo",
      chooseFile: "Escolher arquivo",
      noFilesSelected: "Nenhum arquivo selecionado",
      onlyPDFFilesAllowed: "Apenas arquivos PDF são permitidos",
    },
    user: {
      errorGetUser: "Ocorreu um erro ao buscar informações do usuário",
    },
    utils: {
      create: "Criar",
      edit: "Editar",
      rename: "Renomear",
      delete: "Deletar",
      confirm: "Confirmar",
      cancel: "Cancelar",
      yes: "Sim",
      no: "Não",
      download: "Baixar",
      deleted: "Deletado",
    },
    errors: {
      serverOffline: "O servidor encontra-se offline.",
    },
  },
};
export default ptBrTranslations;
