import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";
import { ReactComponent as LogoSvg } from "../../assets/images/logo.svg";

import { createSelector } from "reselect";
// hooks
import { useRedux } from "../../hooks/index";

// actions
import { changeTab } from "../../redux/actions";

// costants
import LightDarkMode from "../../components/LightDarkMode";
import { TABS } from "../../constants/index";

// menu
import { useTranslation } from "react-i18next";
import I18n from "../../components/I18n";
import Translator from "../../components/Translator";
import { UserProfile } from "../../data/models/user";
import { useUser } from "../../hooks/UserContext/UserContext";
import { keycloak } from "../../utils/keycloack-auth";
import { MenuItemType } from "./menu";

const LogoLightSVG = () => {
  return <LogoSvg />;
};

const LogoDarkSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>

      <Link to="#" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

interface MenuNavItemProps {
  item: MenuItemType;
  selectedTab: TABS.SEARCH | TABS.FILES;
  onChangeTab: (id: TABS.SEARCH | TABS.FILES) => void;
}
const MenuNavItem = ({ item, selectedTab, onChangeTab }: MenuNavItemProps) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  };
  return (
    <>
      <NavItem className={item.className} id={`${item.key}-container`}>
        <NavLink
          href="#"
          active={selectedTab === item.tabId}
          id={item.key}
          role="tab"
          onClick={onClick}
        >
          <i className={item.icon}></i>
        </NavLink>
      </NavItem>
      <UncontrolledTooltip target={`${item.key}-container`} placement="right">
        {item.tooltipTitle}
      </UncontrolledTooltip>
    </>
  );
};

interface ProfileDropdownMenuProps {
  onChangeTab: (id: TABS.SEARCH | TABS.FILES) => void;
  userProfile: UserProfile;
}
const ProfileDropdownMenu = ({
  onChangeTab,
  userProfile,
}: ProfileDropdownMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      className="profile-user-dropdown"
      toggle={toggle}
    >
      <DropdownToggle nav className="bg-transparent">
        <img
          src={userProfile?.imageProfile}
          alt=""
          className="profile-user rounded-circle"
        />
      </DropdownToggle>
      <DropdownMenu>
        {/* TODO: Add the profile manager */}
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          // onClick={() => onChangeTab(TABS.USERS)}
        >
          Profile <i className="bx bx-user-circle text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          // onClick={() => onChangeTab(TABS.SETTINGS)}
        >
          Setting <i className="bx bx-cog text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          href="/auth-changepassword"
        >
          Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
        </DropdownItem> */}

        <DropdownItem />
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          tag="a"
          onClick={() => keycloak.logout()}
        >
          <Translator path="menu.logout" /> <i className="bx bx-log-out-circle text-muted ms-1"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SideMenu = ({ onChangeLayoutMode }: any) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();

  const { userProfile } = useUser();

  const menuItems: MenuItemType[] = [
    {
      id: 1,
      key: "pills-bookmark-tab",
      icon: "bx bx-search",
      tooltipTitle: t("menu.search"),
      tabId: TABS.SEARCH,
    },
    {
      id: 2,
      key: "pills-chat-tab",
      icon: "bx bx-file",
      tooltipTitle: t("menu.files"),
      tabId: TABS.FILES,
    },
  ];

  const errorData = createSelector(
    (state: any) => state.Layout,
    state => ({
      activeTab: state.activeTab,
      layoutMode: state.layoutMode,
    }),
  );
  // Inside your component
  const { activeTab, layoutMode } = useAppSelector(errorData);

  /* 
    tab activation
    */
  const [selectedTab, setSelectedTab] = useState<TABS.SEARCH | TABS.FILES>(
    TABS.SEARCH,
  );
  const onChangeTab = (id: TABS.SEARCH | TABS.FILES) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  return (
    <div className="side-menu flex-lg-column">
      {/* LOGO */}
      <Logo />
      {/* end navbar-brand-box */}

      {/* Start side-menu nav */}
      <div className="flex-lg-column my-0 sidemenu-navigation">
        <Nav pills className="side-menu-nav" role="tablist">
          {(menuItems || []).map((item: MenuItemType, key: number) => (
            <MenuNavItem
              item={item}
              key={key}
              selectedTab={selectedTab}
              onChangeTab={onChangeTab}
            />
          ))}

          {/* change mode */}
          <LightDarkMode
            layoutMode={layoutMode}
            onChangeLayoutMode={onChangeLayoutMode}
          />
          <I18n />
          {/* profile menu dropdown */}
          <ProfileDropdownMenu
            onChangeTab={onChangeTab}
            userProfile={userProfile}
          />
        </Nav>
      </div>
      {/* end side-menu nav */}
    </div>
  );
};

export default SideMenu;
