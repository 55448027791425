import * as api from "./api.service";

export class UserService {
  baseUrl = "user";

  async getUserInformation() {
    const response = await api.get(`${this.baseUrl}`);

    return response;
  }
}
