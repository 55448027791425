import classNames from "classnames";
import { Col, Row } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import logoComplete from '../../../assets/images/logo-complete.svg';
import HeaderBarBack from "../../../components/HeaderBarBack";
import SearchBarAutocomplete, { Item } from "../../../components/SearchBarAutocomplete";
import Translator from "../../../components/Translator";
import { TABS } from "../../../constants";
import { useRedux } from "../../../hooks";
import { useChats } from "../../../hooks/ChatContext/ChatContext";
import { useSideMenu } from "../../../hooks/SideMenuContext/SideMenuContext";
import { changeTab } from "../../../redux/actions";

const Welcome = () => {

  const { sendMessage, setNullToSelectedChat } = useChats();
  const { dispatch } = useRedux();
  const { setShowSideMenu, isMobile } = useSideMenu();


  const navigateToTabFile = () => {
    dispatch(changeTab(TABS.FILES));
  }

  const handleSelectSuggestion = (item: Item) => {
    handleSubmit(item.name);
  }

  const handleSubmit = (value: string) => {
    sendMessage({
      chatId: uuidv4(),
      content: value
    })
  }

  const handleBackClick = () => {
    setNullToSelectedChat();
    setShowSideMenu(true);
  }

  return (
    <>
      <HeaderBarBack onClick={handleBackClick} />
      <div className="chat-main-content-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={7} md={9}>
            <div className="p-2 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <img alt="logo" src={logoComplete} />
              </div>
              <h6>
                <Translator path="welcome.findInformation" parameters={{ companyName: 'Unbabel' }} />
              </h6>
              <p className="text-muted mb-4">
                <Translator path="welcome.enterYourQuestionBelow" />
              </p>
              <SearchBarAutocomplete onSelect={handleSelectSuggestion} onEnterKey={handleSubmit} />
            </div>
            <div className="d-flex justify-content-center align-content-center">
              <button
                type="button"
                className={classNames("btn btn-outline-primary align-items-center d-flex p-3 border-0 gap-2", { 'd-none': isMobile })}
                onClick={() => navigateToTabFile()}
                disabled={isMobile}
              >
                <i style={{ fontSize: '1.5rem' }} className="bx bxs-file-plus"></i>
                <span>
                  <Translator path="file.addFile" />
                </span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Welcome;
