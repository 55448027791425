import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import BrazilFlag from "../../assets/images/i18n/flags/brazil-flag.svg";
import USAFlag from "../../assets/images/i18n/flags/usa-flag.svg";
import { LanguageAvaiableType } from '../../i18n/locales';
import Flag from './Flag';
import './styles.scss';

const I18n = () => {
    const { i18n } = useTranslation()

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    function handleChangeLanguage(language: keyof LanguageAvaiableType) {
        i18n.changeLanguage(language);
        setDropdownOpen(false);
    }

    const flags: Array<{ image: string, key: keyof LanguageAvaiableType, }> = useMemo(() => [
        {
            image: BrazilFlag,
            key: 'pt-BR',
        },
        {
            image: USAFlag,
            key: 'en-US'
        }
    ], [])

    const selectedLanguage = i18n.language
    return (
        <div className="flags-container">
            <Dropdown
                nav
                isOpen={dropdownOpen}
                toggle={toggle}
            >
                <DropdownToggle nav className="bg-transparent">
                    <Flag
                        image={flags.find(flag => flag.key === selectedLanguage)?.image ?? ''}
                        isSelected={true}
                        onClick={() => { }}
                    />
                </DropdownToggle>
                <DropdownMenu>
                    {
                        flags.map((flag, index) => (
                            <Flag
                                key={index}
                                image={flag.image}
                                isSelected={flag.key === selectedLanguage}
                                onClick={() => handleChangeLanguage(flag.key)}
                            />
                        ))
                    }
                </DropdownMenu>
            </Dropdown>

        </div>
    )
}

export default I18n;