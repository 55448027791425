import { Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/Default/index";

// layouts
import NonAuthLayout from "../layouts/NonAuth/index";
import { AuthProtected } from "./AuthProtected";

import { ChatProvider } from "../hooks/ChatContext/ChatContext";
import { UserProvider } from "../hooks/UserContext/UserContext";
import { privateRoutes, publicRoutes } from "./allRoutes";
import { FileProvider } from "../hooks/FileContext/FileContext";
import { SideMenuProvider } from "../hooks/SideMenuContext/SideMenuContext";

const Index = (props: any) => {
  return (
    <Routes>
      <Route>
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}
      </Route>

      <Route>
        {privateRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <UserProvider>
                  <FileProvider>
                    <ChatProvider>
                      <SideMenuProvider>
                        <DefaultLayout>{route.component}</DefaultLayout>
                      </SideMenuProvider>
                    </ChatProvider>
                  </FileProvider>
                </UserProvider>
              </AuthProtected>
            }
            key={idx}
          // exact={true}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Index;
