import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { toast } from 'react-toastify';
import { searchSuggestions } from '../data/searchSuggestions';
import './styles.css';

export interface Item {
    id: string;
    name: string;
}

interface SearchBarAutocompleteProps {
    onEnterKey: (term: string) => void;
    onSelect: (item: Item) => void;
    onHover?: (item: Item) => void;
    onFocus?: () => void;
    onClear?: () => void;
}

const SearchBarAutocomplete = ({
    onEnterKey, onSelect, onHover, onFocus, onClear
}: SearchBarAutocompleteProps) => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState<Item[]>(searchSuggestions);

    const fetchSuggestions = useCallback(async (searchTerm: string) => {
        try {
            // TODO: Adjust to fetch suggestions
            // const response = await fetch(`YOUR_API_ENDPOINT?query=${encodeURIComponent(searchTerm)}`);
            // const data = await response.json();
            const data = searchSuggestions;
            setSuggestions(data);
        } catch (error) {
            toast.error(t('welcome.errorFetchSuggestions'))
            console.error('Error fetching suggestions:', error);
        }
    }, [t]);

    const formatResult = (item: any) => {
        return (
            <div className='d-flex align-items-center justify-content-start m-0 py-1' style={{
                gap: '0.5rem',
            }}>
                <i className="bx bx-search align-content-center"></i>
                <span>{item.name}</span>
            </div>
        )
    }

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setSuggestions([]);
        } else {
            fetchSuggestions(searchTerm);
        }
    }, [fetchSuggestions, searchTerm]);

    const handleOnSearch = (term: string) => {
        setSearchTerm(term);
    };

    const handleOnEnter = useCallback((term: string) => {
        onEnterKey(term);
    }, [onEnterKey]);

    let previousKeyCode: string = '';

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if ((event.code === "Enter" || event.code === "NumpadEnter") && !['ArrowDown', 'ArrowUp'].includes(previousKeyCode)) {
                handleOnEnter((event?.target as any)?.value);
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            previousKeyCode = event.code;
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <div className='d-flex justify-content-between align-items-center pe-2 border-radius-12'>
            <div className='d-flex' style={{ width: '100%' }}>
                <div className='w-100'>
                    <ReactSearchAutocomplete
                        items={suggestions}
                        onSearch={handleOnSearch}
                        onSelect={onSelect}
                        onHover={onHover}
                        onFocus={onFocus}
                        onClear={onClear}
                        inputDebounce={100}
                        resultStringKeyName="name"
                        placeholder={t('welcome.writeYourSearch')}
                        showNoResultsText={t('welcome.noResults')}
                        styling={{
                            height: '60px',
                            borderRadius: searchTerm ? '12px 0 12px 12px' : '12px 0 0 12px',
                            border: '2px solid #EAEAEA',
                            boxShadow: '0px 1px 3px 0px rgba(40, 16, 16, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                            iconColor: '#222F5B',
                            searchIconMargin: '16px',
                            hoverBackgroundColor: '#F3F6FD',
                        }}
                        showIcon={false}
                        autoFocus={true}
                        maxLength={1710}
                        formatResult={formatResult}
                    />
                </div>
            </div>
            <button
                type="submit"
                className="d-flex btn btn-primary btn-lg chat-send waves-effect waves-light btn btn-primary justify-content-center align-items-center"
                onClick={() => {
                    if (searchTerm.trim() !== '') {
                        onEnterKey(searchTerm)
                    }
                }}
                style={{
                    width: 60,
                    height: 62,
                    borderRadius: '0 12px 12px 0',
                    boxShadow: '0px 1px 3px 0px rgba(40, 16, 16, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                }}
            >
                <i className="bx bx-search align-content-center"></i>
            </button>
        </div>
    );
}

export default SearchBarAutocomplete;