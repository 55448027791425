export const searchSuggestions: { id: string, name: string }[] = [
    {
        id: 'How can I use TowerLLM to win a customer considering using ChatGPT for translations?',
        name: 'How can I use TowerLLM to win a customer considering using ChatGPT for translations?'
    },
    {
        id: 'How can I simply explain our pricing to customers?',
        name: 'How can I simply explain our pricing to customers?'
    },
    {
        id: 'Who is our buyer persona?',
        name: 'Who is our buyer persona?'
    },
    {
        id: 'How can we win a customer who is also talking to Smartling?',
        name: 'How can we win a customer who is also talking to Smartling?'
    },
    {
        id: 'What types of pipelines do we offer?',
        name: 'What types of pipelines do we offer?'
    },
    {
        id: 'What are all the template pipelines?',
        name: 'What are all the template pipelines?'
    },
    {
        id: 'How can we win a customer over Lilt?',
        name: 'How can we win a customer over Lilt?'
    },
    {
        id: 'How can we win a customer over Lokalise?',
        name: 'How can we win a customer over Lokalise?'
    },
    {
        id: 'How does Unbabel compare against LSPs?',
        name: 'How does Unbabel compare against LSPs?'
    },
    {
        id: 'Why should a customer choose the Premium Creative pipeline instead of the Hybrid Plus pipeline?',
        name: 'Why should a customer choose the Premium Creative pipeline instead of the Hybrid Plus pipeline?'
    },
    {
        id: 'Provide detailed customer success stories and specific metrics on how Unbabel has helped other companies improve their multilingual customer support efficiency and reduce costs.',
        name: 'Provide detailed customer success stories and specific metrics on how Unbabel has helped other companies improve their multilingual customer support efficiency and reduce costs.'
    },
    {
        id: 'Give me detailed projections or case studies that illustrate the ROI clients can expect from using Unbabel, including cost savings, efficiency improvements, and impact on customer satisfaction metrics like NPS and CSAT.',
        name: 'Give me detailed projections or case studies that illustrate the ROI clients can expect from using Unbabel, including cost savings, efficiency improvements, and impact on customer satisfaction metrics like NPS and CSAT.'
    }
];