import { useFiles } from '../../../hooks/FileContext/FileContext';

const FilesSelected = () => {
    const { selectedFile } = useFiles()

    return (
        <>
            <h2>{selectedFile?.name}</h2>
            <p>
                {selectedFile?.dataUrl}
            </p>
        </>
    );
}

export default FilesSelected;