import { Chat } from "../models/chat";
import * as api from "./api.service";

export interface ISendMessage {
    chatId: string,
    content: string
}

export class ChatService {
    baseUrl = "chat";

    async getChatsByUser() {
        const response = await api.get<Chat[]>(`${this.baseUrl}/user`);

        return response;
    }

    async getChatById(chatId: string) {
        const response = await api.get<Chat>(`${this.baseUrl}/${chatId}`);

        return response;
    }

    async sendMessage(data: ISendMessage) {
        const response = await api.post(`${this.baseUrl}`, data);

        return response;
    }
}
