import { ITranslation } from "./translation.type";

const enUsTranslations: ITranslation = {
  translations: {
    chats: {
      search: "Search",
      newSearch: "New search",
      yoursChats: "Your chats",
      searchingChat: "An error occurred while searching for a chat",
      searchingChats: "An error occurred while searching for chats",
    },
    chat: {
      you: "You",
      searching: "searching",
      attachFile: "Attach file",
      referencesUsed: "References used",
      send: "Send",
      youHaveSelectedImages: "You have selected {{count}} images",
      youHaveSelectedFiles: "You have selected {{count}} files",
      youHaveSelectedFilesAndImages:
        "You have selected {{countFiles}} files and {{countImages}} images",
      typeSearchHere: "Type your search here...",
    },
    menu: {
      logout: "Logout",
      search: "Search",
      files: "Files",
    },
    welcome: {
      findInformation: "Find any information you need about {{companyName}}.",
      enterYourQuestionBelow:
        "Get instant information on products, pricing, competitors, and more",
      writeYourSearch: "Ask me anything!",
      noResults: "No results",
      errorFetchSuggestions: "An error occurred while fetching suggestions",
    },
    file: {
      addFile: "Add file",
      file: "File",
      errorGetFiles: "An error occurred while fetching files",
      noFiles: "No files found",
      yours: "Yours",
      fileDeleted: "File deleted successfully",
      errorDeleteFile: "An error occurred while deleting the file",
      insertFile:
        "Insert a file to provide more accurate answers for you and anyone you allow access.",
      renameFile: "Rename file",
      fileSent: "File sent successfully",
      errorSendFile: "An error occurred while sending the file",
      fileRenamed: "File renamed successfully",
      errorRenameFile: "An error occurred while renaming the file",
      chooseFile: "Choose file",
      noFilesSelected: "No files selected",
      onlyPDFFilesAllowed: "Only PDF files are allowed!",
    },
    user: {
      errorGetUser: "An error occurred while fetching user information",
    },
    utils: {
      create: "Create",
      edit: "Edit",
      rename: "Rename",
      delete: "Delete",
      confirm: "Confirm",
      cancel: "Cancel",
      yes: "Yes",
      no: "No",
      download: "Download",
      deleted: "Deleted",
    },
    errors: {
      serverOffline: "Server is offline",
    },
  },
};
export default enUsTranslations;
