import axios, { AxiosResponse } from "axios";
import { t } from "i18next";
import { toast } from "react-toastify";
import { ErrorEnum } from "../../constants/error";
import { keycloak } from "../../utils/keycloack-auth";

const baseUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create();

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

axios.interceptors.request.use(
  async request => {
    await sleep(2000);
    request.headers["Authorization"] = `Bearer ${keycloak.token ?? ""}`;
    return request;
  },
  (error: Error) => {
    console.error("Request Error Intercepted", error);
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (!error.response && error === "Network Error") {
      toast.error(t("errors.serverOffline"));
      return Promise.reject(new Error(ErrorEnum.SERVER_OFFLINE));
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const keycloakJson = JSON.parse(
        process.env.REACT_APP_KEYCLOAK_JSON ?? "",
      );

      const body = {
        client_id: keycloakJson.resource,
        client_secret: keycloakJson.credentials.secret,
        refresh_token: keycloak.token ?? "",
      };

      const { data } = await refreshLogin(body);

      //   localStorage.setItem(KeyStorage.storagedToken, data.access_token);
      //   localStorage.setItem(KeyStorage.storagedRefreshToken, data.refresh_token);

      originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

      return retryRequest(originalRequest);
    }
    return Promise.reject(error);
  },
);

export const get = async <T = any>(path: string, params?: any): Promise<T> => {
  return await axios.get(`${baseUrl}/${path}`, { params });
};

export const post = async <T = any>(
  path: string,
  body: any,
  params?: any,
): Promise<AxiosResponse<T>> => {
  return await axios.post(`${baseUrl}/${path}`, body, params);
};

export const put = async (
  path: string,
  body: any,
): Promise<AxiosResponse<any>> => {
  return await axios.put(`${baseUrl}/${path}`, body);
};

export const patch = async (
  path: string,
  body: any,
): Promise<AxiosResponse<any>> => {
  return await axios.patch(`${baseUrl}/${path}`, body);
};

export const remove = async (
  path: string,
  query?: any,
): Promise<AxiosResponse<any>> => {
  return await axios.delete(`${baseUrl}/${path}`, query);
};

export const refreshLogin = async (body: {
  client_id: any;
  client_secret: any;
  refresh_token: string;
}) => {
  return await axios.post(
    `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.VITE_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    {
      grant_type: "refresh_token",
      ...body,
    },
    {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    },
  );
};

async function retryRequest(request: any) {
  try {
    const response = await axiosInstance(request);
    return response;
  } catch (error: any) {
    if (error.response && error.response.status !== 401) {
      throw error;
    }
    return retryRequest(request);
  }
}
